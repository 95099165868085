












import { computed, defineComponent, reactive } from '@vue/composition-api'
import { ChartData, ChartOptions } from 'chart.js'
import moment from 'moment'

export default defineComponent({
  components: {
    AChartBar: () => import('@/components/atoms/a-chart-bar.vue')
  },

  setup() {
    const state = reactive({
      datacollection: computed(() => {
        const data: ChartData = {}

        data.datasets = [
          {
            label: 'Ostatnie 7 dni',
            data: [],
            backgroundColor: '#4CAF50'
          },
          {
            label: 'Tydzień wcześniej',
            data: [],
            backgroundColor: '#03389f'
          }
        ]

        data.labels = []
        data.datasets[0].data = []
        data.datasets[1].data = []
        
        for (let i = 7; i > 0; i--) {
          data.labels.push(moment().subtract(i, 'days').format('LL'))

          data.datasets[0].data.push(Math.ceil(25000 + Math.random() * 25000))
          data.datasets[1].data.push(Math.ceil(25000 + Math.random() * 25000))
        }

        return data
      }),
      options: computed(() => {
        const data: ChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              label: context => context.datasetIndex == 0 ? context.xLabel?.toString() || 'W tym miesiącu' : moment(context.xLabel, 'LL').subtract(1, 'month').format('LL') || 'W tym samym dniu, miesiąc wcześniej',
              // eslint-disable-next-line
              title: context => `${/* context[0].yLabel */ '[DEMO]'} km`
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0,
                suggestedMax: 72000,
              // eslint-disable-next-line
                callback: value => `${/* value */ '[DEMO]'} km`
              }
            }]
          },
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 30
            }
          }
        }

        return data
      })
    })

    return { state }
  }
})
